import { UUID } from '@beelday/common'
import { PublishBitrates } from 'common/publish-bitrates'
import { RootState } from 'common/redux'
import {
	RoomAddress,
	RoomId,
	RoomType,
	UserRole,
	VcrConfig,
	VcrId,
} from 'common/types'
import { isEqual, keyBy } from 'lodash'
import { createSelector } from 'reselect'
import { getUserGroup } from 'room/common/model/room'
import { selectJoinedRoomAddress } from 'room/common/redux'
import { RemoteUserStream, SceneVideoUser } from './model/types'

type Action =
	| { type: 'SET_REMOTE_USER_STREAMS'; remoteUserStreams: RemoteUserStream[] }
	| { type: 'SET_LOCAL_MEDIA_STREAM'; stream?: MediaStream }
	| { type: 'SET_LOCAL_SCREEN_SHARE_STREAM'; stream?: MediaStream }
	| { type: 'SET_SCENE_VIDEO_USERS'; sceneVideoUsers: SceneVideoUser[] }
	// | {
	// 		type: 'SET_OUTSIDE_SCENE_AUDIO_USERS'
	// 		outsideSceneAudioUsers: SceneVideoUser[]
	//   }
	| { type: 'SET_VCR_ROOM_ADDRESS'; roomAddress: RoomAddress }
	| { type: 'CLEAR_VCR_ROOM_ADDRESS' }
	| { type: 'SET_PUBLISH_BITRATE'; bitrate: number }
	| { type: 'FORCE_PUBLISH_BITRATE'; bitrate: number | undefined }
	| { type: 'MUTE_VCR_SCENE' }
	| { type: 'UNMUTE_VCR_SCENE' }
	| {
			type: 'SSE_CONFERENCE_CALL_EXPECTED'
			payload: {
				context: RoomAddress
				placeId: RoomId
				call: { vcrId: VcrId; publish?: boolean; subscribe?: boolean }
			}
	  }

type State = {
	localUserStream?: MediaStream
	localScreenShareStream?: MediaStream
	remoteUserStreams: RemoteUserStream[]
	sceneVideoUsers: SceneVideoUser[]
	// outsideSceneAudioUsers: SceneVideoUser[]
	vcrRoomAddress?: RoomAddress
	hasVcr: boolean
	vcrId?: VcrId
	vcrConfig: VcrConfig
	mutedVcrScene: boolean
	publishBitrate: number
	forceBitrate?: number
}

const initialState: State = {
	remoteUserStreams: [],
	sceneVideoUsers: [],
	// outsideSceneAudioUsers: [],
	mutedVcrScene: false,
	hasVcr: false,
	vcrConfig: {
		publish: false,
		subscribe: true,
	},
	publishBitrate: PublishBitrates.low,
}

export const setLocalUserStream = (stream?: MediaStream): Action => {
	return { type: 'SET_LOCAL_MEDIA_STREAM', stream }
}

export const setLocalScreenShareStream = (stream?: MediaStream): Action => {
	return { type: 'SET_LOCAL_SCREEN_SHARE_STREAM', stream }
}

export const setRemoteUserStreams = (
	remoteUserStreams: RemoteUserStream[]
): Action => {
	return { type: 'SET_REMOTE_USER_STREAMS', remoteUserStreams }
}

export const setSceneVideoUsers = (
	sceneVideoUsers: SceneVideoUser[]
): Action => {
	return { type: 'SET_SCENE_VIDEO_USERS', sceneVideoUsers }
}

// export const setOutsideSceneAudioUsers = (
// 	outsideSceneAudioUsers: SceneVideoUser[]
// ): Action => {
// 	return { type: 'SET_OUTSIDE_SCENE_AUDIO_USERS', outsideSceneAudioUsers }
// }

export const setVcrRoomAddress = (roomAddress: RoomAddress): Action => {
	return { type: 'SET_VCR_ROOM_ADDRESS', roomAddress }
}

export const clearVcrRoomAddress = (): Action => {
	return { type: 'CLEAR_VCR_ROOM_ADDRESS' }
}

export const muteVcrScene = (): Action => {
	return { type: 'MUTE_VCR_SCENE' }
}

export const unmuteVcrScene = (): Action => {
	return { type: 'UNMUTE_VCR_SCENE' }
}

export const setPublishBitrate = (bitrate: number): Action => {
	return { type: 'SET_PUBLISH_BITRATE', bitrate }
}

export const forcePublishBitrate = (bitrate: number | undefined): Action => {
	return { type: 'FORCE_PUBLISH_BITRATE', bitrate }
}

export const videoConferenceMediaReducer = (
	state: State = initialState,
	action: Action
): State => {
	switch (action.type) {
		case 'SET_REMOTE_USER_STREAMS':
			return {
				...state,
				remoteUserStreams: action.remoteUserStreams,
			}
		case 'SET_LOCAL_MEDIA_STREAM':
			return {
				...state,
				localUserStream: action.stream,
			}
		case 'SET_SCENE_VIDEO_USERS':
			return {
				...state,
				sceneVideoUsers: action.sceneVideoUsers,
			}
		// case 'SET_OUTSIDE_SCENE_AUDIO_USERS':
		// 	return {
		// 		...state,
		// 		outsideSceneAudioUsers: action.outsideSceneAudioUsers,
		// 	}
		case 'SET_VCR_ROOM_ADDRESS': {
			if (isEqual(action.roomAddress, state.vcrRoomAddress)) {
				return state
			}
			return {
				...state,
				vcrRoomAddress: action.roomAddress,
				vcrId: undefined,
			}
		}
		case 'CLEAR_VCR_ROOM_ADDRESS':
			return { ...initialState, mutedVcrScene: state.mutedVcrScene }
		case 'MUTE_VCR_SCENE':
			return {
				...state,
				mutedVcrScene: true,
			}
		case 'UNMUTE_VCR_SCENE':
			return {
				...state,
				mutedVcrScene: false,
			}
		case 'SET_PUBLISH_BITRATE':
			return {
				...state,
				publishBitrate: action.bitrate,
			}
		case 'FORCE_PUBLISH_BITRATE':
			return {
				...state,
				forceBitrate: action.bitrate,
			}
		case 'SSE_CONFERENCE_CALL_EXPECTED':
			if (action.payload.placeId === state.vcrRoomAddress?.roomId) {
				return {
					...state,
					hasVcr: true,
					vcrId: action.payload.call?.vcrId,
					vcrConfig: {
						publish: action.payload.call?.publish ?? state.vcrConfig.publish,
						subscribe:
							action.payload.call?.subscribe ?? state.vcrConfig.subscribe,
					},
				}
			} else {
				return state
			}
		case 'SET_LOCAL_SCREEN_SHARE_STREAM':
			return {
				...state,
				localScreenShareStream: action.stream,
			}
		default:
			return state
	}
}

export const allStreams: (state: RootState) => SceneVideoUser[] =
	createSelector(
		(state: RootState) => [
			...state.videoConferenceMedia.sceneVideoUsers,
			// ...state.videoConferenceMedia.outsideSceneAudioUsers,
		],
		(state: RootState) => state.workflow.user,
		(state: RootState) => state.interactionScheme,
		(streams, me, interactionScheme) => {
			const xd = streams.map(s => {
				const userState = interactionScheme?.usersState[s.userId]

				return {
					...s,
					isSelfView: s.userId === me?.id,
					muted: userState?.audioMuted || false,
					videoMuted: userState?.videoMuted || false,
				}
			})
			console.log('SELECTOR FOR ALL STREAMS', xd)
			return xd
		}
	)

const selectStreamsInGroup = createSelector(
	(state: RootState) => state.workflow.user,
	(state: RootState) => state.sessionInfo.training,
	(state: RootState) => state.groupRoom,
	selectJoinedRoomAddress,
	(me, training, groupRoom, joinedRoom): string[] | null => {
		//Show all users in group if reviewing tasks
		const groups = groupRoom.groups
		const trainerGroupId =
			groupRoom.trainerState.status === 'TRAINER_IN_GROUP' &&
			groupRoom.trainerState.inGroup
		const isTrainer = me?.role === UserRole.UPPER_ECHELON
		const inGroupRoom = joinedRoom?.roomType === RoomType.GroupRoom
		const inGroup = inGroupRoom && (!isTrainer || trainerGroupId)
		if (isTrainer && trainerGroupId) {
			//Show all users in group and the trainer
			const group = groups[trainerGroupId]
			return [me.id, ...group?.users.map(u => u.id)]
		} else if (me && groups && inGroup) {
			//Allow only trainer and group members
			const trainerId = training?.upperEchelon.userId
			const myGroup = getUserGroup(groups, me)
			const trainerInCurrentGroup =
				trainerGroupId === myGroup?.groupDescription.id

			let users = myGroup?.users.map(u => u.id) || []
			if (trainerInCurrentGroup && trainerId) {
				users.push(trainerId)
			} else {
				users = users.filter(u => u !== trainerId)
			}
			return users
		}
		return null
	}
)

export const selectAllowedStreams: (state: RootState) => SceneVideoUser[] =
	createSelector(
		(state: RootState) => [
			...state.videoConferenceMedia.sceneVideoUsers,
			// ...state.videoConferenceMedia.outsideSceneAudioUsers,
		],
		(state: RootState) => state.workflow.user,
		(state: RootState) => state.interactionScheme,
		(state: RootState) => state.groupRoom,
		selectStreamsInGroup,
		(streams, me, interactionScheme, groupRoom, usersInCurrentGroup) => {
			if (usersInCurrentGroup) {
				streams = streams.filter(s => usersInCurrentGroup.includes(s.userId))
			}

			const isTrainer = me?.role === UserRole.UPPER_ECHELON

			streams.map(s => ({
				...s,
				isSelfView: s.userId === me?.id,
				muted: interactionScheme?.usersState[s.userId]?.audioMuted || false,
			}))

			if (
				isTrainer &&
				interactionScheme.joinedRoom?.roomType === RoomType.GroupRoom &&
				groupRoom.trainerState.status === 'TRAINER_LOOKS_AT_GROUPS_VIEW'
			) {
				const groupId = groupRoom.trainerState.listensToGroup

				if (!groupId) {
					return streams.map(s => ({
						...s,
						muted: true,
					}))
				}

				const usersInGroup = groupRoom.groups[groupId].users.map(s => s.id)
				const streamsInGroup = streams.filter(s =>
					usersInGroup.includes(s.userId)
				)

				return streams.map(s => ({
					...s,
					muted: !streamsInGroup.includes(s),
				}))
			} else if (
				isTrainer &&
				interactionScheme.joinedRoom?.roomType === RoomType.GroupRoom &&
				groupRoom.trainerState.status === 'TRAINER_BE_RIGHT_BACK'
			) {
				const groupId = groupRoom.trainerState.listensToGroup

				if (!groupId) {
					return streams.map(s => ({
						...s,
						muted: true,
					}))
				}

				const usersInGroup = groupRoom.groups[groupId].users.map(s => s.id)
				const streamsInGroup = streams.filter(s =>
					usersInGroup.includes(s.userId)
				)

				return streams.map(s => ({
					...s,
					muted: !streamsInGroup.includes(s),
				}))
			}

			return streams
		}
	)

export const othersStream = createSelector(
	allStreams,
	(state: RootState) => state.workflow.user,
	(streams, me) => streams.filter(s => s.userId !== me?.id)
)

export const selectMyStream = createSelector(
	(state: RootState) => state.videoConferenceMedia.sceneVideoUsers,
	(state: RootState) => state.workflow.user,
	(streams, me) => streams.find(s => s.userId === me?.id)
)

export const sceneVideoUsersById: (
	state: RootState
) => Record<UUID, SceneVideoUser> = createSelector(allStreams, all =>
	keyBy(all, 'userId')
)

export const othersStreamsById: (
	state: RootState
) => Record<UUID, SceneVideoUser> = createSelector(othersStream, all =>
	keyBy(all, 'userId')
)

export const endUserStreams: (state: RootState) => SceneVideoUser[] =
	createSelector(
		allStreams,
		(state: RootState) => state.room.users,
		(streams, users) => {
			const nonEndUsers = users
				.filter(u => u.role !== 'END_USER')
				.map(u => u.id)
			return streams.filter(s => !nonEndUsers.includes(s.userId))
		}
	)
export const selectPublishBitRate = (state: RootState): number | undefined =>
	state.videoConferenceMedia.publishBitrate
export const selectForceBitRate = (state: RootState): number | undefined =>
	state.videoConferenceMedia.forceBitrate
export const selectVCRId = (state: RootState): VcrId | undefined =>
	state.videoConferenceMedia.vcrId

export const selectVCRConfig = (state: RootState): VcrConfig | undefined =>
	state.videoConferenceMedia.vcrConfig

export const selectLocalStream = (state: RootState): MediaStream | undefined =>
	state.videoConferenceMedia.localUserStream

export const selectLocalScreenShareStream = (
	state: RootState
): MediaStream | undefined => state.videoConferenceMedia.localScreenShareStream

export const selectRemoteScreenShare = createSelector(
	[allStreams],
	streams => streams.find(s => s.stream?.screenShareTrackId)?.stream
)

export const selectHasVcr = (state: RootState): boolean =>
	state.videoConferenceMedia.hasVcr
