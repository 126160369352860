import { userApi } from '@beelday/common'
import { Config } from 'common/config'
import { RootState } from 'common/redux'
import {
	PROFILE_MISSING,
	SpotlightChange,
	UserId,
	UserProfile,
} from 'common/types'
import { map, noop } from 'lodash'
import { FC, useCallback, useEffect, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useSelector as useSelect } from 'app-redux'
import { useAssertedWorkflowUser } from 'room/common/use-workflow-user'
import { useProfileFor, useProfilesFor } from 'users/redux'
import useFetchMissingProfiles from 'users/use-fetch-missing-profiles'
import { EmbeddedVCR } from './embedded-vcr'
import { EmbeddedJunoVCR } from './embedded-vcr-juno'
import { toVideoUsers } from './model/converters'
import { RemoteUserStream, VideoUser } from './model/types'
import VideoScene from './model/video-scene'
import {
	// setOutsideSceneAudioUsers,
	setRemoteUserStreams,
	setSceneVideoUsers,
} from './redux'
import { selectTraining } from 'session-info/redux'
import { selectAllUsers } from 'room/common/redux'

type Props = {
	presenterId?: UserId
	spotlightPresenter: boolean
}

const toOptionalVideoUser = (
	userId: string,
	userName: string,
	stream?: MediaStream
): VideoUser | undefined => {
	if (!stream) {
		return undefined
	}
	return { userId, userName, stream: stream }
}

type VideoSceneHook = {
	addSpotlight: (userId: string) => void
	removeSpotlight: (userId: string) => void
}

const useVideoScene = (
	localMediaStream: MediaStream | undefined,
	remoteStreams: RemoteUserStream[],
	props: Props,
	roomUsers: Array<UserProfile | PROFILE_MISSING>,
	userId: UserId,
	userProfile: UserProfile | undefined | PROFILE_MISSING,
	muted: boolean,
	trainerId?: string
): VideoSceneHook => {
	console.log('remoteStreams', remoteStreams)
	const dispatch = useDispatch()
	const users = useSelector(selectAllUsers)
	const scene = useMemo(
		() =>
			new VideoScene(
				{
					presenterDelay: Config.videoSpotlightDelayMs,
					trainerId: trainerId,
					users: users,
				},
				() => {
					const {
						sceneUsers,
						// outsideSceneUsers
					} = scene.allUsers

					// dispatch(setSceneVideoUsers(scene.allUsers))
					dispatch(setSceneVideoUsers(sceneUsers))
					// dispatch(setOutsideSceneAudioUsers(outsideSceneUsers))
				}
			),
		[dispatch, trainerId, users]
	)

	const withoutMe = useMemo(
		() => roomUsers.filter(u => u && u.id !== userId) as UserProfile[],
		[roomUsers, userId]
	)

	const remoteVideoUsers = useMemo(
		() => toVideoUsers(remoteStreams, withoutMe),
		[withoutMe, remoteStreams]
	)

	const userName = userApi.getDisplayName(userProfile)

	useEffect(() => {
		const {
			sceneUsers,
			// outsideSceneUsers
		} = scene.setProps({
			fixedPresenterId: props.presenterId,
			localUser: toOptionalVideoUser(userId, userName || '', localMediaStream),
			spotlightPresenter: props.spotlightPresenter,
		})

		dispatch(setSceneVideoUsers(sceneUsers))
		// dispatch(setOutsideSceneAudioUsers(outsideSceneUsers))
	}, [
		dispatch,
		localMediaStream,
		props.presenterId,
		props.spotlightPresenter,
		userId,
		userName,
		scene,
	])

	// const audioVideoStreams = useMemo(
	// 	() => remoteStreams.filter(s => s.stream.getVideoTracks().length > 0),
	// 	[remoteStreams]
	// )
	// const audioStreams = useMemo(
	// 	() => remoteStreams.filter(s => s.stream.getAudioTracks().length > 0),
	// 	[remoteStreams]
	// )

	useEffect(() => {
		console.log('remote users passed to setter', remoteVideoUsers)
		const {
			sceneUsers,
			// outsideSceneUsers
		} = scene.setRemoteUsers(remoteVideoUsers)

		dispatch(setSceneVideoUsers(sceneUsers))
		// dispatch(setOutsideSceneAudioUsers(outsideSceneUsers))
	}, [dispatch, remoteVideoUsers, scene])

	useEffect(() => {
		const {
			sceneUsers,
			// outsideSceneUsers
		} = scene.setSceneMuted(muted)

		dispatch(setSceneVideoUsers(sceneUsers))
		// dispatch(setOutsideSceneAudioUsers(outsideSceneUsers))

		// dispatch(setSceneVideoUsers(scene.setSceneMuted(muted)))
	}, [dispatch, muted, scene])

	const addSpotlight = useCallback(
		(userId: string) => {
			scene.addSpotlight(userId)
		},
		[scene]
	)

	const removeSpotlight = useCallback(
		(userId: string) => {
			scene.removeSpotlight(userId)
		},
		[scene]
	)

	return {
		addSpotlight,
		removeSpotlight,
	}
}

export const VideoConferenceMedia: FC<Props> = props => {
	const dispatch = useDispatch()
	const workflowUser = useAssertedWorkflowUser()
	const localMediaStream = useSelector(
		(state: RootState) => state.videoConferenceMedia.localUserStream
	)

	const training = useSelect(state => selectTraining(state.sessionInfo))
	const trainerId = training?.upperEchelon.userId

	const roomUsers = useSelector(
		(state: RootState) => map(state.room.users, 'id'),
		shallowEqual
	)
	const workflowUserProfile = useProfileFor(workflowUser.id)
	const roomProfiles = useProfilesFor(roomUsers)
	const remoteStreams = useSelector(
		(state: RootState) => state.videoConferenceMedia.remoteUserStreams
	)
	const mutedVcrScene = useSelector(
		(state: RootState) => state.videoConferenceMedia.mutedVcrScene
	)

	useFetchMissingProfiles([...roomUsers, workflowUser.id])

	const { addSpotlight, removeSpotlight } = useVideoScene(
		localMediaStream,
		remoteStreams,
		props,
		roomProfiles,
		workflowUser.id,
		workflowUserProfile,
		mutedVcrScene,
		trainerId
	)

	const handleSpotlightChange = useCallback(
		(change: SpotlightChange) => {
			if (change.spotlight) {
				addSpotlight(change.userId)
			} else {
				removeSpotlight(change.userId)
			}
		},
		[addSpotlight, removeSpotlight]
	)

	const handleRemoteStreams = useCallback(
		streams => dispatch(setRemoteUserStreams(streams)),
		[dispatch]
	)

	return Config.vcrManager === 'vcr' ? (
		<EmbeddedVCR
			userId={workflowUser.id}
			onLocalMediaStream={noop}
			onRemoteStreams={handleRemoteStreams}
			onSpotlightChange={handleSpotlightChange}
		/>
	) : (
		<EmbeddedJunoVCR
			userId={workflowUser.id}
			onRemoteStreams={handleRemoteStreams}
			onSpotlightChange={handleSpotlightChange}
		/>
	)
}
