import { SceneVideoUser } from '../model/types'

export type VideoSize = {
	width: number
	height: number
}

export type Section = {
	sceneVideoUsers: SceneVideoUser[]
	videoSize: VideoSize
}

type WidthSection = Section & {
	width: number
}

type HeightSection = Section & {
	height: number
}

type WidthHeightSection = WidthSection & HeightSection

export type LeftSection = WidthSection
export type TopSection = WidthHeightSection
export type RightSection = WidthSection
export type BottomSection = HeightSection

const toPresenters = (sceneVideoUsers: SceneVideoUser[]) => {
	return sceneVideoUsers.filter(user => user.presenter)
}

const toUsers = (sceneVideoUsers: SceneVideoUser[]) => {
	return sceneVideoUsers.filter(user => !user.presenter)
}

export class PresenterGridLayout {
	left: LeftSection
	top: TopSection
	right: RightSection
	bottom: BottomSection

	constructor(
		sceneVideoUsers: SceneVideoUser[],
		presenterStyle: PresenterStyle
	) {
		// const xd = sceneVideoUsers
		// 	.filter(user => !user.presenter)
		// 	.flatMap(user => {
		// 		const bang = []
		// 		for (let i = 0; i < 20; i++) {
		// 			bang.push(user)
		// 		}
		// 		return bang
		// 	})

		const presenters = toPresenters(sceneVideoUsers)
		const filtered = sceneVideoUsers.filter(svu => svu.isOnScene)
		const users = toUsers(filtered)

		const size = users.length

		if (size === 0) {
			this.left = {
				width: 0,
				videoSize: {
					width: 0,
					height: 0,
				},
				sceneVideoUsers: [],
			}
			this.top = {
				width: 1,
				height: 1,
				videoSize: {
					width: 1,
					height: 1,
				},
				sceneVideoUsers: presenters,
			}
			this.right = {
				width: 0,
				videoSize: {
					width: 0,
					height: 0,
				},
				sceneVideoUsers: [],
			}
			this.bottom = {
				height: 0,
				videoSize: {
					width: 0,
					height: 0,
				},
				sceneVideoUsers: [],
			}
		} else if (size === 1 && presenterStyle === 'distinguished') {
			this.left = {
				width: 0.25,
				videoSize: {
					width: 0,
					height: 0,
				},
				sceneVideoUsers: [],
			}
			this.top = {
				width: 0.5,
				height: 0.5,
				videoSize: {
					width: 1,
					height: 1,
				},
				sceneVideoUsers: presenters,
			}
			this.right = {
				width: 0.25,
				videoSize: {
					width: 0,
					height: 0,
				},
				sceneVideoUsers: [],
			}
			this.bottom = {
				height: 0.5,
				videoSize: {
					width: 0.5,
					height: 1,
				},
				sceneVideoUsers: users,
			}
		} else if (size === 1 && presenterStyle === 'spotlight') {
			this.left = {
				width: 0,
				videoSize: {
					width: 0,
					height: 0,
				},
				sceneVideoUsers: [],
			}
			this.top = {
				width: 1,
				height: 0,
				videoSize: {
					width: 0,
					height: 0,
				},
				sceneVideoUsers: [],
			}
			this.right = {
				width: 0,
				videoSize: {
					width: 0,
					height: 0,
				},
				sceneVideoUsers: [],
			}
			this.bottom = {
				height: 1,
				videoSize: {
					width: 0.5,
					height: 0.5,
				},
				sceneVideoUsers: [...presenters, ...users],
			}
		} else if (size <= 6) {
			this.left = {
				width: 1 / (size * 2),
				videoSize: {
					width: 0,
					height: 0,
				},
				sceneVideoUsers: [],
			}
			this.top = {
				width: (size - 1) / size,
				height: (size - 1) / size,
				videoSize: {
					width: 1,
					height: 1,
				},
				sceneVideoUsers: presenters,
			}
			this.right = {
				width: 1 / (size * 2),
				videoSize: {
					width: 0,
					height: 0,
				},
				sceneVideoUsers: [],
			}
			this.bottom = {
				height: 1 / size,
				videoSize: {
					width: 1 / size,
					height: 1,
				},
				sceneVideoUsers: users,
			}
		} else if (size <= 10) {
			this.left = {
				width: 1 / 5,
				videoSize: {
					width: 0,
					height: 0,
				},
				sceneVideoUsers: [],
			}
			this.top = {
				width: 3 / 5,
				height: 3 / 5,
				videoSize: {
					width: 1,
					height: 1,
				},
				sceneVideoUsers: presenters,
			}
			this.right = {
				width: 1 / 5,
				videoSize: {
					width: 0,
					height: 0,
				},
				sceneVideoUsers: [],
			}
			this.bottom = {
				height: 2 / 5,
				videoSize: {
					width: 1 / 5,
					height: 1 / 2,
				},
				sceneVideoUsers: users,
			}
		} else if (size === 11) {
			this.left = {
				width: 1 / 5,
				videoSize: {
					width: 1,
					height: 1 / 3,
				},
				sceneVideoUsers: users.slice(0, 3),
			}
			this.top = {
				width: 3 / 5,
				height: 3 / 4,
				videoSize: {
					width: 1,
					height: 1,
				},
				sceneVideoUsers: presenters,
			}
			this.right = {
				width: 1 / 5,
				videoSize: {
					width: 1,
					height: 1 / 3,
				},
				sceneVideoUsers: users.slice(3, 6),
			}
			this.bottom = {
				height: 1 / 4,
				videoSize: {
					width: 1 / 5,
					height: 1,
				},
				sceneVideoUsers: users.slice(6),
			}
		} else if (size <= 14) {
			this.left = {
				width: 1 / 5,
				videoSize: {
					width: 1,
					height: 1 / 2,
				},
				sceneVideoUsers: users.slice(0, 2),
			}
			this.top = {
				width: 3 / 5,
				height: 2 / 4,
				videoSize: {
					width: 1,
					height: 1,
				},
				sceneVideoUsers: presenters,
			}
			this.right = {
				width: 1 / 5,
				videoSize: {
					width: 1,
					height: 1 / 2,
				},
				sceneVideoUsers: users.slice(2, 4),
			}
			this.bottom = {
				height: 2 / 4,
				videoSize: {
					width: 1 / 5,
					height: 1 / 2,
				},
				sceneVideoUsers: users.slice(4),
			}
		} else if (size <= 28) {
			const lanes = Math.floor((size - 13) / 4) + 5
			const videosOnOneSide = lanes - 2
			this.left = {
				width: 1 / lanes,
				videoSize: {
					width: 1,
					height: 1 / videosOnOneSide,
				},
				sceneVideoUsers: users.slice(0, videosOnOneSide),
			}
			this.top = {
				width: (lanes - 2) / lanes,
				height: (lanes - 2) / lanes,
				videoSize: {
					width: 1,
					height: 1,
				},
				sceneVideoUsers: presenters,
			}
			this.right = {
				width: 1 / lanes,
				videoSize: {
					width: 1,
					height: 1 / videosOnOneSide,
				},
				sceneVideoUsers: users.slice(videosOnOneSide, 2 * videosOnOneSide),
			}
			this.bottom = {
				height: 2 / lanes,
				videoSize: {
					width: 1 / lanes,
					height: 1 / 2,
				},
				sceneVideoUsers: users.slice(2 * videosOnOneSide),
			}
		} else if (size <= 32) {
			this.left = {
				width: 2 / 8,
				videoSize: {
					width: 1 / 2,
					height: 1 / 4,
				},
				sceneVideoUsers: users.slice(0, 8),
			}
			this.top = {
				width: 4 / 8,
				height: 4 / 6,
				videoSize: {
					width: 1,
					height: 1,
				},
				sceneVideoUsers: presenters,
			}
			this.right = {
				width: 2 / 8,
				videoSize: {
					width: 1 / 2,
					height: 1 / 4,
				},
				sceneVideoUsers: users.slice(8, 16),
			}
			this.bottom = {
				height: 2 / 6,
				videoSize: {
					width: 1 / 8,
					height: 1 / 2,
				},
				sceneVideoUsers: users.slice(16),
			}
		} else {
			throw new Error(`Unhandled number of participants: ${size}`)
		}
	}
}

export type PresenterStyle = 'distinguished' | 'spotlight'
