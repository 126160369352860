import { logger } from '@beelday/common'
import { useSelector } from 'app-redux'
import { pull } from 'lodash'
import React, { FC, useEffect, useState } from 'react'
import { BeeldayMediaStream, SceneVideoUser } from './model/types'
import { selectAllowedStreams } from './redux'

const log = logger.create('VideoComponentsManager')

class VideoComponentsManager {
	private mounted: Record<string, number> = {}
	private video: Record<string, HTMLVideoElement> = {}
	private audio: Record<string, HTMLAudioElement> = {}
	private streams: SceneVideoUser[] = []
	private observers: (() => void)[] = []
	constructor(streams: SceneVideoUser[]) {
		this.streamsChanged(streams)
	}

	public attach(userId: string): HTMLVideoElement | undefined {
		const mounted = this.mounted[userId] ?? 0
		this.mounted[userId] = mounted + 1
		return this.video[userId]
	}

	public detach(userId: string): void {
		const mounted = this.mounted[userId] ?? 0
		this.mounted[userId] = Math.max(mounted, 0)
		if (!this.mounted[userId]) {
			try {
				this.video[userId]?.pause()
			} catch (e) {
				log.error('Failed to pause video on unmount', e)
			}
		}
	}

	public attachAudio(userId: string): HTMLAudioElement | undefined {
		const mounted = this.mounted[userId] ?? 0
		this.mounted[userId] = mounted + 1
		return this.audio[userId]
	}
	public detachAudio(userId: string): void {
		const mounted = this.mounted[userId] ?? 0
		this.mounted[userId] = Math.max(mounted, 0)
		if (!this.mounted[userId]) {
			try {
				this.audio[userId]?.pause()
			} catch (e) {
				log.error('Failed to pause video on unmount', e)
			}
		}
	}

	public streamsChanged(streams: SceneVideoUser[]): boolean {
		if (!Object.is(this.streams, streams)) {
			const currentVideos = this.video || {}
			const currentAudios = this.audio || {}

			this.video = {}
			this.audio = {}
			this.streams = streams
			const userIds = streams.map(stream => stream.userId)
			for (const key in currentVideos) {
				const stream = streams.find(stream => stream.userId === key)
				const video = currentVideos[key]
				if (
					userIds.includes(key) &&
					(stream?.isOnScene === true || stream?.presenter === true)
				) {
					this.video[key] = video
				} else {
					//Make sure we remove media and video from DOM
					video.srcObject = null
					video.src = ''
					video.pause()
					video.remove()
				}
			}

			for (const key in currentAudios) {
				const stream = streams.find(stream => stream.userId === key)
				const audio = currentAudios[key]
				if (
					userIds.includes(key) &&
					stream?.isOnScene === false &&
					stream?.presenter === false
				) {
					this.audio[key] = audio
				} else {
					//Make sure we remove media and audio from DOM
					audio.srcObject = null
					audio.src = ''
					audio.pause()
					audio.remove()
				}
			}

			// streams.forEach(stream => {
			// 	if (!stream.stream) return
			// 	const videos = Object.values(currentVideos)
			// 	const audios = Object.values(currentAudios)

			// 	const video = videos.find(
			// 		video => video.dataset['streamId'] === stream.stream?.id
			// 	)
			// 	const audio = audios.find(
			// 		audio => audio.dataset['streamId'] === stream.stream?.id
			// 	)

			// 	if (!!audio && !!video) {
			// 		if (stream.isOnScene || stream.presenter) {
			// 			this.video[stream.userId] = video
			// 		} else {
			// 			this.audio[stream.userId] = audio
			// 		}
			// 	}
			// })

			streams.forEach(stream => {
				console.log('stream', stream)
				if (!stream.stream) return

				const mute = stream.isSelfView || stream.muted

				if (stream.isOnScene === true || stream.presenter === true) {
					const currentVideo = currentVideos[stream.userId]
					if (!currentVideo) {
						const video = this.createVideoElement(stream)
						if (video) this.video[stream.userId] = video
					} else if (currentVideo.dataset['streamId'] !== stream.stream.id) {
						currentVideo.srcObject = stream.stream
						currentVideo.dataset['streamId'] = stream.stream.id
						currentVideo.muted = mute
					} else if (currentVideo.muted !== mute) {
						currentVideo.muted = mute
					}
				} else if (stream.isOnScene === false && stream.presenter === false) {
					const currentAudio = currentAudios[stream.userId]
					if (!currentAudio) {
						const audio = this.createAudioElement(stream)
						if (audio) this.audio[stream.userId] = audio
					} else if (currentAudio.dataset['streamId'] !== stream.stream.id) {
						currentAudio.srcObject = stream.stream
						currentAudio.dataset['streamId'] = stream.stream.id
						currentAudio.muted = mute
					} else if (currentAudio.muted !== mute) {
						currentAudio.muted = mute
					}
				}
			})

			console.log('MANAGER', this.video, this.audio, this.streams)

			this.notifyObservers()
			return true
		}
		return false
	}

	public resetStreamVideo(userId: string): void {
		delete this.video[userId]
		const stream = this.streams.find(stream => stream.userId === userId)
		const element = stream && this.createVideoElement(stream)
		if (element) {
			this.video[userId] = element
		}
		this.notifyObservers()
	}

	public resetStreamAudio(userId: string): void {
		delete this.audio[userId]
		const stream = this.streams.find(stream => stream.userId === userId)
		const element = stream && this.createAudioElement(stream)
		if (element) {
			this.audio[userId] = element
		}
		this.notifyObservers()
	}

	public onStreamsChanged(observer: () => unknown): () => void {
		this.observers.push(observer)
		return () => pull(this.observers, observer)
	}

	private notifyObservers(): void {
		this.observers.forEach(observer => observer())
	}

	private createVideoElement(
		stream: SceneVideoUser
	): HTMLVideoElement | undefined {
		if (stream?.stream) {
			log.info('Create video element for user', stream.userId)

			const video = document.createElement('video')
			video.dataset['streamId'] = stream.stream.id
			video.dataset['userId'] = stream.userId
			//Remove screen sharing tracks
			let mediaStream = stream.stream
			if (stream.stream.screenShareTrackId) {
				mediaStream = new BeeldayMediaStream(
					mediaStream
						.getTracks()
						.filter(track => track.id !== mediaStream.screenShareTrackId)
				)
			}
			if (stream.isSelfView) {
				//Ignore audio tracks on self view
				video.srcObject = new MediaStream(mediaStream.getVideoTracks())
			} else {
				video.srcObject = mediaStream
			}
			video.autoplay = true
			video.playsInline = true
			video.style.width = '100%'
			video.style.height = '100%'
			video.style.objectFit = 'cover'
			video.muted = stream.isSelfView || stream.muted
			return video
		}
	}

	private createAudioElement(
		stream: SceneVideoUser
	): HTMLAudioElement | undefined {
		if (stream?.stream) {
			log.info('Create audio element for user', stream.userId)

			const audio = document.createElement('audio')
			audio.dataset['streamId'] = stream.stream.id
			audio.dataset['userId'] = stream.userId
			//Remove screen sharing tracks
			let mediaStream = stream.stream
			if (stream.stream.screenShareTrackId) {
				mediaStream = new BeeldayMediaStream(
					mediaStream
						.getTracks()
						.filter(track => track.id !== mediaStream.screenShareTrackId)
						.filter(track => track.kind === 'audio')
				)
			}
			if (stream.isSelfView) {
				//Ignore audio tracks on self view
				audio.srcObject = new MediaStream()
			} else {
				audio.srcObject = mediaStream
			}
			audio.autoplay = true
			audio.muted = stream.isSelfView || stream.muted
			return audio
		}
	}
}

export const useVideoComponents = (): VideoComponentsManager => {
	const videoComponents = React.useContext(videoComponentsContext)
	if (!videoComponents) {
		throw new Error(
			'useVideoComponents must be used within a VideoComponentsProvider'
		)
	}

	return videoComponents
}

export const videoComponentsContext =
	React.createContext<VideoComponentsManager | null>(null)

export const ProvideVideoComponents: FC = ({ children }) => {
	const streams = useSelector(selectAllowedStreams)
	const [manager] = useState<VideoComponentsManager>(
		() => new VideoComponentsManager(streams)
	)

	useEffect(() => {
		manager.streamsChanged(streams)
	}, [streams, manager])

	if (manager) {
		return (
			<videoComponentsContext.Provider value={manager}>
				{children}
			</videoComponentsContext.Provider>
		)
	} else {
		return null
	}
}
