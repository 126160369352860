import { logger } from '@beelday/common'
import { useEffect, useRef } from 'react'
import { useVideoComponents } from 'video-conference-media'

const log = logger.create('VideoStream')

type Props = {
	userId: string
	className?: string
}
export const OffSceneAudioStream = ({
	userId,
	className,
}: Props): JSX.Element => {
	const containerRef = useRef<HTMLDivElement>(null)
	const audios = useVideoComponents()

	useEffect(() => {
		const handleStreamsChanged = () => {
			const current = containerRef.current
			const comp = audios.attachAudio(userId)
			if (current) {
				console.log('AUDIO-STREAM: Attaching audio for: ', userId)
				const currentAudio = current.children[0] as HTMLAudioElement
				if (
					comp &&
					comp.dataset['streamId'] !== currentAudio?.dataset['streamId']
				) {
					console.log('AUDIO-STREAM: Replacing audio for: ', userId)
					current.replaceChildren(comp)

					if (comp.paused) {
						comp
							.play()
							.then(() => {
								log.info('AUDIO-STREAM: Audio play success for: ', userId)
							})
							.catch(e => {
								log.error(
									`AUDIO-STREAM: Failed to play paused for ${userId}, reseting stream`,
									e
								)
								audios.resetStreamAudio(userId)
							})
					}
				}
			}
		}
		handleStreamsChanged()
		return audios.onStreamsChanged(handleStreamsChanged)
	}, [userId, audios])

	useEffect(() => {
		return () => {
			log.info('AUDIO-STREAM: Detaching audio for: ', userId)
			audios.detachAudio(userId)
		}
	}, [userId, audios])

	return <div ref={containerRef} className={className} />
}
