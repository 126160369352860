import { arrays } from '@beelday/common'
import type { UserId } from 'common/types'
import type { SceneVideoUser, VideoUser } from './types'
import { User } from '@sentry/react'

export type SceneProps = {
	fixedPresenterId?: UserId
	localUser?: VideoUser
	spotlightPresenter: boolean
}

const equals = (left: any, right: any): boolean =>
	!Object.entries(left).find(e => !(e[0] in right) || right[e[0]] !== e[1])

type SpotlightUser = {
	userId: UserId
	startedTalking: number
	stoppedTalking?: number
}

export type Config = {
	presenterDelay: number
	presenterCloseDelay: number
	trainerId: UserId
	users: User[]
}

const DEFAULT_CONFIG = {
	presenterDelay: 3000,
	presenterCloseDelay: 6000,
}

export default class VideoScene {
	private presenterDelay: number
	private presenterCloseDelay: number
	private spotlightChangeTimeout?: NodeJS.Timeout
	private onSpotlightChange?: (spotlight: UserId | undefined) => unknown
	private talkingUsers: Map<UserId, SpotlightUser> = new Map()
	private spotlightUser?: SpotlightUser
	private props: SceneProps | undefined
	private remoteUsers: VideoUser[] = []
	private sceneVideoUsers: SceneVideoUser[] = []
	private muted = false
	private cachedSceneVideoUsers: Record<UserId, SceneVideoUser> = {}
	private trainerId?: string
	private users?: User[] = []

	constructor(
		options: Partial<Config>,
		onSpotlightChange?: (spotlight: UserId | undefined) => unknown
	) {
		const config = { ...DEFAULT_CONFIG, ...options }

		this.onSpotlightChange = onSpotlightChange
		this.presenterDelay = config.presenterDelay
		this.presenterCloseDelay = config.presenterCloseDelay
		this.trainerId = config.trainerId
		this.users = config.users
	}

	private handleSpotlightChange(): void {
		if (this.isSpotlightQuietFor(this.presenterDelay)) {
			const longestTalking = arrays.minBy(
				this.talkingUsers.values(),
				u => u.startedTalking
			)
			if (longestTalking) {
				this.spotlightUser = longestTalking
			} else if (this.isSpotlightQuietFor(this.presenterCloseDelay)) {
				this.spotlightUser = undefined
			} else {
				//Wait few more seconds before removing spotlight if everyone is quiet
				setTimeout(() => this.handleSpotlightChange(), this.presenterCloseDelay)
			}
		}
		this.onSpotlightChange?.(this.spotlightUser?.userId)
	}

	addSpotlight = (userId: UserId): void => {
		const current = this.talkingUsers.get(userId)
		if (current) {
			if (current.stoppedTalking) {
				current.stoppedTalking = undefined
			}
		} else {
			this.talkingUsers.set(userId, {
				userId: userId,
				startedTalking: Date.now(),
			})
		}

		if (this.spotlightUser?.userId === userId) {
			this.spotlightUser.stoppedTalking = undefined
		}

		this.handleSpotlightChange()
	}

	private isSpotlightQuietFor(ms: number): boolean {
		if (!this.spotlightUser) return true

		return this.spotlightUser.stoppedTalking
			? this.spotlightUser.stoppedTalking <= Date.now() - ms
			: false
	}

	removeSpotlight = (userId: UserId): void => {
		if (this.talkingUsers.delete(userId)) {
			if (this.spotlightUser?.userId === userId) {
				this.spotlightUser.stoppedTalking = Date.now()
				if (this.spotlightChangeTimeout) {
					clearTimeout(this.spotlightChangeTimeout)
				}
				this.spotlightChangeTimeout = setTimeout(
					() => this.handleSpotlightChange(),
					this.presenterDelay
				)
			}

			this.handleSpotlightChange()
		}
	}

	setProps = (
		props: SceneProps
	): {
		sceneUsers: SceneVideoUser[]
		// outsideSceneUsers: SceneVideoUser[]
	} => {
		this.props = props
		return this.allUsers
	}

	setRemoteUsers = (
		remoteUsers: VideoUser[]
	): {
		sceneUsers: SceneVideoUser[]
		//  outsideSceneUsers: SceneVideoUser[]
	} => {
		// const sceneVideoUsers = remoteUsers.filter(
		// 	e => e.stream?.getVideoTracks().length
		// )

		// const outsideSceneAudioUsers = remoteUsers.filter(
		// 	e => e.stream?.getAudioTracks().length
		// )

		console.log('remote users in setter', remoteUsers)
		this.remoteUsers = remoteUsers
		console.log('remote users in setter after set', this.remoteUsers)
		// this.outsideSceneUsers = outsideSceneAudioUsers
		return this.allUsers
	}

	setSceneMuted = (
		muted: boolean
	): {
		sceneUsers: SceneVideoUser[]
		//  outsideSceneUsers: SceneVideoUser[]
	} => {
		this.muted = muted
		return this.allUsers
	}

	private isPresenter = (userId: UserId) =>
		this.presenterUserId === userId &&
		this.presenterUserId !== this.props?.localUser?.userId

	private isTalking = (userId: string) => this.talkingUsers.has(userId)

	private toRemoteSceneVideoUser = (videoUser: VideoUser): SceneVideoUser => {
		return {
			...videoUser,
			spotlight: this.isTalking(videoUser.userId),
			presenter: this.isPresenter(videoUser.userId),
			muted: this.muted,
			isSelfView: false,
		}
	}

	private toLocalSceneVideoUser = (videoUser: VideoUser): SceneVideoUser => {
		return {
			...videoUser,
			spotlight: this.isTalking(videoUser.userId),
			presenter: this.isPresenter(videoUser.userId),
			muted: true,
			isSelfView: true,
		}
	}

	private get recalculatedSceneVideoUsers(): SceneVideoUser[] {
		if (!this.props) {
			return []
		}

		console.log('this.remoteUsers ', this.remoteUsers)

		const remoteSceneVideoUsers = this.remoteUsers.map(
			this.toRemoteSceneVideoUser
		)
		if (this.props.localUser) {
			return [
				...remoteSceneVideoUsers,
				this.toLocalSceneVideoUser(this.props.localUser),
			]
		}
		return remoteSceneVideoUsers
	}

	get allUsers(): {
		sceneUsers: SceneVideoUser[]
		// outsideSceneUsers: SceneVideoUser[]
	} {
		const userNumberInRoomByStreams = new Map<number, SceneVideoUser>()

		const current = this.recalculatedSceneVideoUsers

		this.sceneVideoUsers = []
		// this.outsideSceneUsers = []

		// const sceneUsers: SceneVideoUser[] = []
		// const outsideSceneUsers: SceneVideoUser[] = []

		console.log('current', current)

		const trainer = current.find(user => user.userId === this.trainerId)

		const currentWithoutTrainer = current.filter(
			u => u.userId !== this.trainerId
		)
		const usersWithoutTrainer = this.users?.filter(u => u.id !== this.trainerId)

		usersWithoutTrainer?.forEach((user, index) => {
			const stream = currentWithoutTrainer.find(u => u.userId === user.id)
			if (stream) {
				userNumberInRoomByStreams.set(index, stream)
			}
		})

		// const trainerRemoteStream = current.splice(index, 1)
		// console.log('TRAINER REMOTE STREAM', trainer)

		// trainerRemoteStream.map(user => {
		// console.log('TRAINER', user, user.userId, this.trainerId)
		// if (trainer && trainer.userId === this.trainerId) {
		// 	const cached = this.cachedSceneVideoUsers[trainer.userId]
		// 	if (!cached || !equals(trainer, cached)) {
		// 		this.cachedSceneVideoUsers[trainer.userId] = trainer

		// 		// sceneUsers.push(user)

		// 		this.sceneUsers.push({ ...trainer, isOnScene: true })
		// 	} else {
		// 		this.sceneUsers.push({ ...cached, isOnScene: true })

		// 		// sceneUsers.push(cached)
		// 	}
		// }
		// })
		// const currentWithoutTrainer = current.filter(
		// 	u => u.userId !== this.trainerId
		// )
		// console.log('TALKING USERS', this.talkingUsers)
		console.log('MOST IMPORTANT', userNumberInRoomByStreams)

		// const filtered = userNumberInRoomByStreams
		// 	.entries()
		// 	.filter(([, user]) => user.userId === this.trainerId)
		// 	.toArray()

		if (trainer) {
			// userNumberInRoomByStreams.delete(filtered[0][0])
			this.sceneVideoUsers = [{ ...trainer, isOnScene: true }]
		}

		console.log('MOST IMPORTANT V2 ', userNumberInRoomByStreams)

		userNumberInRoomByStreams.forEach((user, index) => {
			// if (user.userId === this.trainerId) {
			// this.sceneUsers = [{ ...user, isOnScene: true }, ...this.sceneUsers]
			// } else
			if (index > 0 && this.sceneVideoUsers.length > 1) {
				// outsideSceneUsers.push(user)
				// this.outsideSceneUsers.push({ ...user, isOnScene: false })

				this.sceneVideoUsers.push({ ...user, isOnScene: false })
			} else {
				const cached = this.cachedSceneVideoUsers[user.userId]
				if (!cached || !equals(user, cached)) {
					this.cachedSceneVideoUsers[user.userId] = user
					// sceneUsers.push(user)
					this.sceneVideoUsers.push({ ...user, isOnScene: true })
				} else {
					// sceneUsers.push(cached)
					this.sceneVideoUsers.push({ ...cached, isOnScene: true })
				}
			}
		})

		// currentWithoutTrainer.forEach((user, index) => {
		// 	console.log('forEach', user, index)

		// 	if (index > 0) {
		// 		// outsideSceneUsers.push(user)
		// 		this.outsideSceneUsers.push({ ...user, isOnScene: false })
		// 	} else {
		// 		const cached = this.cachedSceneVideoUsers[user.userId]
		// 		if (!cached || !equals(user, cached)) {
		// 			this.cachedSceneVideoUsers[user.userId] = user

		// 			// sceneUsers.push(user)
		// 			this.sceneUsers.push({ ...user, isOnScene: true })
		// 		} else {
		// 			// sceneUsers.push(cached)

		// 			this.sceneUsers.push({ ...cached, isOnScene: true })
		// 		}
		// 	}
		// })

		console.log('MAPPED', this.sceneVideoUsers)
		//  this.outsideSceneUsers)

		return {
			sceneUsers: this.sceneVideoUsers,
			// outsideSceneUsers: this.outsideSceneUsers,
		}
	}

	private get presenterUserId() {
		if (!this.props) return undefined

		const { fixedPresenterId, spotlightPresenter } = this.props
		const availableUserIds = this.remoteUsers.map(user => user.userId)

		if (fixedPresenterId && availableUserIds.includes(fixedPresenterId)) {
			console.log('return fixed presenter id', fixedPresenterId)
			return fixedPresenterId
		}

		if (spotlightPresenter) {
			console.log('return spotlight presenter', this.spotlightUser?.userId)
			return this.spotlightUser?.userId
		}
	}
}
